import { AtomicTransactionComposer, makeAssetTransferTxnWithSuggestedParamsFromObject, makePaymentTxnWithSuggestedParamsFromObject } from 'algosdk';
import { algodClient } from '../algo';
import { MarketplaceClient } from './client';

const LISTING_MBR = 592_500;
const LISTING_OPTIN_MBR = 100_000;
const MARKETPLACE_APP_ID = 2648336270;
const MARKETPLACE_ADDRESS = '3G4R4IIR2G6KJO7TGEICFC75DI5UYSV6SYBRCUT3PX2K34S2VLSCA2XKAU';

function createNote(marketplace) {
  const enc = new TextEncoder();
  const note = enc.encode(JSON.stringify({ marketplace }));
  return note;
}

export async function createListing(sellerSigner, sellerWallet, sender, assetID, sellPrice) {
  if (!sellerSigner || !sellerWallet || !sender || !assetID || !sellPrice) {
    throw new Error('Invalid arguments');
  }

  const marketplaceClient = new MarketplaceClient(
    {
      resolveBy: 'id',
      id: MARKETPLACE_APP_ID,
      sender,
    },
    algodClient
  );

  const listingAtomicTransfer = new AtomicTransactionComposer();

  const suggestedParams = await algodClient.getTransactionParams().do();

  const optInInput = {
    amount: LISTING_OPTIN_MBR,
    from: sellerWallet,
    to: MARKETPLACE_ADDRESS,
    suggestedParams,
  };
  const optInUnsignedTx = makePaymentTxnWithSuggestedParamsFromObject(optInInput);

  const optInPaymentTransaction = {
    txn: optInUnsignedTx,
    signer: sellerSigner,
  };

  const marketplaceOptIn = marketplaceClient.appClient.getABIMethod('optin');

  listingAtomicTransfer.addMethodCall({
    appID: MARKETPLACE_APP_ID,
    sender: sellerWallet,
    suggestedParams: { ...suggestedParams, fee: 2000, flatFee: true },
    appForeignAssets: [assetID],
    methodArgs: [optInPaymentTransaction, assetID],
    method: marketplaceOptIn,
    signer: sellerSigner,
  });

  const listingUnsignedTx = makePaymentTxnWithSuggestedParamsFromObject({
    amount: LISTING_MBR,
    from: sellerWallet,
    to: MARKETPLACE_ADDRESS,
    suggestedParams,
  });
  const listingPaymentTransaction = {
    txn: listingUnsignedTx,
    signer: sellerSigner,
  };

  const listingSendAssetUnsignedTx = makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: sellerWallet,
    to: MARKETPLACE_ADDRESS,
    amount: 1,
    assetIndex: assetID,
    suggestedParams,
  });
  const listingSendAssetTransaction = {
    txn: listingSendAssetUnsignedTx,
    signer: sellerSigner,
  };

  const listMethod = marketplaceClient.appClient.getABIMethod('list');

  const addInput = {
    appID: MARKETPLACE_APP_ID,
    sender: sellerWallet,
    signer: sellerSigner,
    suggestedParams: { ...suggestedParams, fee: 20000, flatFee: true },
    appForeignAssets: [assetID],
    methodArgs: [
      listingPaymentTransaction,
      listingSendAssetTransaction,
      sellPrice * 1e6,
      'SKVJU7NP7QMAXYZR744DBIQH67SFSKO7ZD46CSSPFZSMLN4CNLY44B24OA', // place where royalties are sent
    ],
    method: listMethod,
    note: createNote('shittykitties.art'),
  };

  listingAtomicTransfer.addMethodCall(addInput);

  const signedTxns = await listingAtomicTransfer.execute(algodClient, 10);

  return signedTxns;
}
export async function buyListing(buyerSigner, buyerWallet, sender, marketplaceListing) {
  const { assetID, price, listingSideMarketplaceWallet, appID, sellerWallet } = marketplaceListing;

  if (!buyerWallet) {
    throw new Error('Please connect your wallet');
  }

  if (!buyerSigner || !sender || !assetID || !price || !listingSideMarketplaceWallet || !sellerWallet) {
    throw new Error('Invalid arguments');
  }

  const marketplaceClient = new MarketplaceClient(
    {
      resolveBy: 'id',
      id: MARKETPLACE_APP_ID,
      sender,
    },
    algodClient
  );

  const asset = await algodClient.getAssetByID(assetID).do();
  const creatorWallet = asset.params.creator;

  const atomicTransfer = new AtomicTransactionComposer();

  const suggestedParams = await algodClient.getTransactionParams().do();

  const buyerOptInTransaction = makeAssetTransferTxnWithSuggestedParamsFromObject({
    from: buyerWallet,
    to: buyerWallet,
    amount: 0,
    assetIndex: assetID,
    suggestedParams,
  });
  atomicTransfer.addTransaction({
    txn: buyerOptInTransaction,
    signer: buyerSigner,
  });

  const purchaseUnsignedTxn = makePaymentTxnWithSuggestedParamsFromObject({
    amount: price * 1e6,
    from: buyerWallet,
    to: MARKETPLACE_ADDRESS,
    suggestedParams,
  });
  const buySignedTxn = { txn: purchaseUnsignedTxn, signer: buyerSigner };

  const purchaseMethod = marketplaceClient.appClient.getABIMethod('purchase');
  const addInput = {
    appID: MARKETPLACE_APP_ID,
    sender: buyerWallet,
    signer: buyerSigner,
    suggestedParams: { ...suggestedParams, fee: 10000, flatFee: true },
    appForeignApps: [appID],
    appForeignAssets: [assetID],
    appAccounts: ['SKVJU7NP7QMAXYZR744DBIQH67SFSKO7ZD46CSSPFZSMLN4CNLY44B24OA', listingSideMarketplaceWallet, creatorWallet, sellerWallet],
    methodArgs: [buySignedTxn, appID, assetID, 'SKVJU7NP7QMAXYZR744DBIQH67SFSKO7ZD46CSSPFZSMLN4CNLY44B24OA', createNote('shittykitties.art')],
    method: purchaseMethod,
  };
  atomicTransfer.addMethodCall(addInput);

  const signedTxns = await atomicTransfer.execute(algodClient, 10);
  return signedTxns;
}

export async function delistListing(sellerSigner, sellerWallet, sender, { assetID, appID, marketplaceWallet }) {
  console.log('delistListing', sellerSigner, sellerWallet, sender, assetID, appID);
  if (!sellerSigner || !sellerWallet || !sender || !assetID || !appID) {
    throw new Error('Invalid arguments');
  }

  const marketplaceClient = new MarketplaceClient(
    {
      resolveBy: 'id',
      id: MARKETPLACE_APP_ID,
      sender,
    },
    algodClient
  );

  const asset = await algodClient.getAssetByID(assetID).do();
  const creatorWallet = asset.params.creator;

  const atomicTransfer = new AtomicTransactionComposer();
  const suggestedParams = await algodClient.getTransactionParams().do();

  // construct an optin transaction for the asset
  const optInInput = {
    suggestedParams,
    from: sellerWallet,
    to: sellerWallet,
    assetIndex: assetID,
  };

  const optInUnsignedTx = makeAssetTransferTxnWithSuggestedParamsFromObject(optInInput);

  const optInTransaction = {
    txn: optInUnsignedTx,
    signer: sellerSigner,
  };
  atomicTransfer.addTransaction(optInTransaction);

  const delistMethod = marketplaceClient.appClient.getABIMethod('delist');
  atomicTransfer.addMethodCall({
    appID: MARKETPLACE_APP_ID,
    sender: sellerWallet,
    signer: sellerSigner,
    suggestedParams: { ...suggestedParams, fee: 7000, flatFee: true },
    appForeignAssets: [assetID],
    appForeignApps: [appID],
    appAccounts: [marketplaceWallet, creatorWallet],
    methodArgs: [appID, assetID],
    method: delistMethod,
    note: createNote('shittykitties.art'),
  });

  const signedTxns = await atomicTransfer.execute(algodClient);

  return signedTxns;
}
